/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0!important; font-family: Roboto, "Helvetica Neue", sans-serif; padding: 0 !important; box-sizing: border-box!important;}
* {
    // margin: 0 !important;
    // padding: 0 !important;
    border: 0 !important;
    outline: 0 !important;
    // font-size: 100% !important;
    // vertical-align: baseline !important;
    box-sizing: border-box !important;
    // background: transparent !important;
}
